@import "../../vars";
.jumbotron h1 {
  font-size: 4rem;
}
.measurements {
  .btn-primary {
    margin-top: 2rem;
  }
}
.neckband {
  background: $silver;
}
.form-group {
  flex-basis: 200px;
}
.credits {
  background: $pink;
}
.footer {
  background: $charcoal;
  color: white;
}
