@import "_vars.scss";

$enable-rounded: false;
$theme-colors: (
  "primary": $lavender,
  "secondary": $peach,
);
$jumbotron-bg: $peach;
$headings-font-weight: 600;
$btn-font-weight: 600;

// Required
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

// Optional
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/jumbotron";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/modal";
@import "node_modules/bootstrap/scss/navbar";
@import "node_modules/bootstrap/scss/utilities";
