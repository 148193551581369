html,
body,
#root,
.App {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Nixie One", cursive !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p,
label {
  font-family: "Lato", sans-serif !important;
}

.large {
  font-size: 1.2rem;
}
